import React, { useState, useEffect } from 'react'
import Wrapper from '../components/wrapper'
import HeroSection from '../components/pageComponents/hiringWithMedRepsHero'
import PostNow from '../components/pageComponents/hiringWithMedRepsPostNow'
import EmployerSlider from '../components/pageComponents/employerSlider'
import Reviews from '../components/pageComponents/hiringWithMedRepsReviews'
import Charts from '../components/pageComponents/hiringWithMedRepsCharts'
import SignUp from '../components/pageComponents/hiringWithMedRepsSignup'
import FaqComponent from '../components/pageComponents/hiringWithMedRepsFaq'
import ContactUs from '../components/pageComponents/hiringWithMedRepsContactUs'
const HiringWithMedReps = ({ location }) => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      function handleResize() {
        if (window.innerWidth > 767) {
          setIsMobile(false)
        } else {
          setIsMobile(true)
        }
      }
      window.addEventListener('resize', handleResize)
    }
  }, [])

  const Children = props => {
    return (
      <>
        <HeroSection {...props} />
        <PostNow {...props} />
        <EmployerSlider headerText={'TRUSTED BY TOP EMPLOYERS'} {...props} />
        <Reviews {...props} />
        <Charts {...props} />
        <SignUp {...props} />
        <FaqComponent {...props} />
        <ContactUs {...props} />
      </>
    )
  }

  return (
    <Wrapper
      location={location}
      hideCTA={true}
      title="Hiring Medical Sales Professionals | MedReps"
      description="Find skilled and experienced professionals who can drive your business forward. With MedReps, you'll attract the best candidates from across the industry and streamline your hiring process with our comprehensive recruitment resources for medical sales professionals."
      employerFacing={true}
    >
      <iframe
        width={'100%'}
        height={isMobile ? 6750 : 3400}
        frameBorder={0}
        src="https://join.medreps.com/hire-with-medreps"
        onLoad={evt => console.log('onload ---', evt?.target)}
      ></iframe>
    </Wrapper>
  )
}
export default HiringWithMedReps
